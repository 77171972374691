<template>
  <!-- Filter By Station -->
  <b-container fluid>
    <b-row>
      <b-col> </b-col>
    </b-row>
    <b-row>
      <b-col class="font-weight-bold pb-3 pt-3">
        <b-nav pills class="w-100">
          <!--
          <b-nav-item active>All</b-nav-item>
          <b-nav-item>Drinks</b-nav-item>
          <b-nav-item>Appetizers</b-nav-item>
          <b-nav-item>Mains</b-nav-item>-->
          <b-nav-item>
            <b-form-select
              v-model="selected"
              :options="station_options"
            ></b-form-select>
          </b-nav-item>
          <b-nav-item class="ml-auto">
            <b-form-select
              v-model="selected"
              :options="view_options"
            ></b-form-select>
          </b-nav-item>
          <!--
          <b-nav-item class="ml-auto queued rounded-left" disabled
            >Queued</b-nav-item
          >
          <b-nav-item class="ready" disabled>Ready</b-nav-item>
          <b-nav-item class="late rounded-right" disabled>Late</b-nav-item>
          -->
        </b-nav>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "TicketFilter",
  data() {
    return {
      selected: null,
      station_options: [{ value: null, text: "Kitchen View" }],
      view_options: [{ value: null, text: "Filter by Station" }],
    };
  },
};
</script>
<style scoped>
.nav-link {
  color: black !important;
}
.active {
  background-color: var(--main-color) !important;
  color: white !important;
}
.disabled {
  color: white !important;
}
.b-nav-dropdown {
  color: black !important;
}
</style>
