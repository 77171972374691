<template>
  <!-- Nav -->
  <b-container fluid>
    <b-row no-gutters>
      <b-col>
        <nav id="nav" class="navbar navbar-expand-lg navbar-light">
          <router-link class="navbar-brand font-weight-bold" id="logo" to="/">
            tblside
          </router-link>
          <router-link
            class="font-weight-bold btn ml-auto"
            to="/login/"
            v-if="!$store.state.authenticated"
            >Sign In</router-link
          >
          <router-link
            class="font-weight-bold btn ml-auto"
            to="/logout/"
            v-if="$store.state.authenticated"
            >Logout</router-link
          >
        </nav>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Nav",
};
</script>
<style scoped>
.navbar {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
a {
  color: white !important;
}
#logo {
  color: black !important;
}
</style>
