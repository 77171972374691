<template>
  <!-- Fulfiller -->
  <b-container fluid>
    <Nav></Nav>
    <TicketFilter></TicketFilter>
    <b-row>
      <TicketCard
        v-for="ticket in sortedTickets"
        :key="'ticket-' + ticket.id"
        :ticket="ticket"
      ></TicketCard>
    </b-row>
  </b-container>
</template>

<script>
import Nav from "../components/Nav.vue";

import TicketFilter from "../components/TicketFilter.vue";
import TicketCard from "../components/TicketCard.vue";

export default {
  name: "Roles",
  components: {
    Nav,
    TicketFilter,
    TicketCard,
  },
  mounted: function() {
    //this.$store.dispatch('connect');
    this.$store.dispatch("getTickets");
  },
  computed: {
    sortedTickets: function() {
      return this._.sortBy(this.$store.state.tickets, "id");
    },
  },
};
</script>
