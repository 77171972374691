<template>
  <div>
    <!-- Order Item -->
    <h6 class="my-0 mb-3">
      <span class="font-weight-bold">{{ item.quantity }} </span
      >{{ item.item_name }}
      <span class="badge ml-2 font-weight-bold" v-if="paymentStatus">Paid</span>
      <span class="badge ml-2 font-weight-bold ready">11:12</span>
      <VueCountdown
        :time="itemTime"
        class="ml-1"
        v-if="countdownTimer && item.status < 3"
        :emit-events="true"
        @progress="addStatus"
        @start="addStatus"
      >
        <template slot-scope="props">
          <span :class="status" class="badge">
            {{ props.minutes }}:{{ props.seconds }}
          </span>
        </template>
      </VueCountdown>
    </h6>
    <p v-if="item.special_notes" class="font-weight-bold">
      {{ item.special_notes }}
    </p>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  name: "OrderItem",
  props: [
    "item",
    "paymentStatus",
    "countdownTimer",
    "countdownStart",
    "appsTicker",
    "mainsTicker",
    "dessertsTicker",
  ],
  components: {
    VueCountdown,
  },
  methods: {
    addStatus: function(data) {
      if (this.item.item_cook_time < parseInt(data.totalSeconds)) {
        return "queued";
      } else if (
        this.item.item_cook_time > parseInt(data.totalSeconds) &&
        !parseInt(data.totalSeconds) > 0
      ) {
        return "ready";
      } else if (parseInt(data.totalSeconds) <= 0) {
        return "late";
      }
    },
  },
  computed: {
    itemTime: function() {
      if (this.item.category_type == 3) {
        return this.appsTicker > 0 ? this.appsTicker : 0;
      }
      if (this.item.category_type == 4) {
        return this.mainsTicker;
      }
      if (this.item.category_type == 5) {
        return this.dessertsTicker;
      }
      return this.item.item_cook_time ? this.item.item_cook_time : 0;
    },
  },
};
</script>
<style scoped>
.card {
  border-color: none !important;
}
li {
  background-color: white !important;
  color: black;
  border-color: white !important;
}
.due {
  background-color: grey !important;
  color: white !important;
}
</style>
