<template>
  <!-- Ticket -->
  <b-col md="4" class="mt-0" v-if="showCard">
    <div class="card shadow-sm mb-3 mx-auto d-block border-0">
      <div class="card-header pb-2">
        <h5 class="card-title font-weight-bold d-inline">
          Ticket #{{ ticket.id }}
          <span class="d-inline float-right">11:12</span>
        </h5>
      </div>
      <div class="card-body pt-0">
        <OrderItem
          v-for="item in sortItems"
          :key="'item-' + item.id"
          :item="item"
        ></OrderItem>
        <button class="font-weight-bold w-100 btn btn-sm primary-blue">
          Ticket Ready
        </button>
      </div>
    </div>
  </b-col>
</template>

<script>
import OrderItem from "../components/OrderItem.vue";

export default {
  name: "TicketCard",
  props: ["ticket"],
  components: {
    OrderItem,
  },
  data: function() {
    return {
      ready: true,
    };
  },
  computed: {
    sortItems: function() {
      return this._.sortBy(this.ticket.items, "category_type");
    },
    showCard: function() {
      return !this._.isEmpty(this.ticket.items);
    },
  },
};
</script>
<style scoped>
.card {
  width: 97.5%;
  border-color: none !important;
}
.card-header {
  background-color: white !important;
  color: black !important;
  border-bottom: 0;
}
@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
}
li {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
}
</style>
